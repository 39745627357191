import React, { useEffect } from 'react';
import Header from '../components/Header';

import '../styles/Halley.css';

function Halley() {
  useEffect(() => {
    const astronautImage = document.getElementById('floating-austronaut');
    const height = window.innerHeight - 50;
    const width = window.innerWidth - 50;

    const handleMouseOver = () => {
      astronautImage.style.position = 'absolute';
      astronautImage.style.top = `${Math.random() * height}px`;
      astronautImage.style.left = `${Math.random() * width}px`;
    };

    if (astronautImage) {
      astronautImage.addEventListener('mouseover', handleMouseOver);

      return () => {
        astronautImage.removeEventListener('mouseover', handleMouseOver);
      };
    }

    return () => {};
  }, []);

  return (
    <>
      <Header />

      <main id="halley-main">
        <section id="halley-section">
          <div id="halley-quote-container">
            <p id="halley-quote">Imagine um mundo ideal e se considere cidadão dele.</p>
          </div>

          <img src="/images/astronaut.png" id="floating-austronaut" alt="astrounat floating in space" />
        </section>
      </main>
    </>
  );
}

export default Halley;
